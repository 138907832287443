<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-uppercase">{{$t('title.order')}}#{{detailLoad.order_id}} </span>
                                    <span><small><ol-status :status="detailLoad.load_status"></ol-status></small></span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <h4><span class="text-uppercase">{{$t('title.order')}}: </span></h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.brand')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.brand ? detailLoad.brand.title : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.model')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.model ? detailLoad.model.title : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.market')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.order_country}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.status')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <ol-status :status="detailLoad.load_status"></ol-status>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.transportRequestAt')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.client_tr_at">
                                            {{detailLoad.client_tr_at ?
                                            $global.utcDateToLocalDate(detailLoad.client_tr_at) : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span
                                            class="text-uppercase">{{$t('title.transportRequestAcceptedAt')}}</span>
                                        </h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.client_tr_accept_at">
                                            {{detailLoad.client_tr_accept_at ?
                                            $global.utcDateToLocalDate(detailLoad.client_tr_accept_at) : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>

                    <div class="mt-3" v-if="detailLoad.under_process">
                        <div class="box border rounded p-4"
                             v-if="detailLoad.under_process.operation === 'spot_inbound_load'">
                            <h4><span class="text-uppercase">{{$t('title.inboundLoad')}}: SPOT-#{{detailLoad.under_process.spot_inbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.spot_inbound_load.supplier_carrier">
                                            {{detailLoad.under_process.spot_inbound_load.supplier_carrier.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.fromLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.spot_inbound_load.from_location">
                                            <print-ad titled="1"
                                                      :item="detailLoad.under_process.spot_inbound_load.from_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.toLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.spot_inbound_load.to_location">
                                            <print-ad titled="1"
                                                      :item="detailLoad.under_process.spot_inbound_load.to_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->

                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.driver')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.spot_inbound_load.driver">
                                            {{detailLoad.under_process.spot_inbound_load.driver ?
                                            detailLoad.under_process.spot_inbound_load.driver.name : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->

                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.transportVehicle')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.spot_inbound_load.transport_vehicle">
                                            {{detailLoad.under_process.spot_inbound_load.transport_vehicle.truck_plate_number}}
                                            <span
                                                v-if="detailLoad.under_process.spot_inbound_load.transport_vehicle.trailer_plate_number">
                                                | {{detailLoad.under_process.spot_inbound_load.transport_vehicle.trailer_plate_number}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row
                                    v-show="detailLoad.under_process.spot_inbound_load.unload_by_carrier_document.length > 0">
                                    <b-col md="6" lg="6" sm="12"><h6>
                                        <span class="text-uppercase">{{$t('title.file')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                <span>

                                    <a v-if="detailLoad.under_process.spot_inbound_load.unload_by_carrier_document.length"
                                       v-b-modal.files-attachments
                                       @click="getAttachmentItem(detailLoad.under_process.spot_inbound_load.unload_by_carrier_document,$t('title.fileAttachment'))"><i
                                        class="fe fe-paperclip"></i>Attachments</a>
                                </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div>
                        </div>
                    </div>

                    <div class="mt-3" v-if="detailLoad.under_process">
                        <div class="box border rounded p-4"
                             v-if="detailLoad.under_process.operation === 'inbound_load'">
                            <h4><span class="text-uppercase">{{$t('title.inboundLoad')}}: #{{detailLoad.under_process.inbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.inbound_load.supplier_carrier">
                                            {{detailLoad.under_process.inbound_load.supplier_carrier.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.fromLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.inbound_load.from_location">
                                            <print-ad titled="1"
                                                      :item="detailLoad.under_process.inbound_load.from_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.toLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.inbound_load.to_location">
                                            <print-ad titled="1"
                                                      :item="detailLoad.under_process.inbound_load.to_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->

                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.driver')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.under_process.inbound_load.driver">
                                            {{detailLoad.under_process.inbound_load.driver ?
                                            detailLoad.under_process.inbound_load.driver.name : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->

                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.transportVehicle')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.inbound_load.transport_vehicle">
                                            {{detailLoad.under_process.inbound_load.transport_vehicle.truck_plate_number}}
                                            <span
                                                v-if="detailLoad.under_process.inbound_load.transport_vehicle.trailer_plate_number">
                                                | {{detailLoad.under_process.inbound_load.transport_vehicle.trailer_plate_number}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row
                                    v-show="detailLoad.under_process.inbound_load.unload_by_carrier_document.length > 0">
                                    <b-col md="6" lg="6" sm="12"><h6>
                                        <span class="text-uppercase">{{$t('title.file')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span>
                                                <a v-if="detailLoad.under_process.inbound_load.unload_by_carrier_document.length"
                                                   v-b-modal.files-attachments
                                                   @click="getAttachmentItem(detailLoad.under_process.inbound_load.unload_by_carrier_document,$t('title.fileAttachment'))"><i
                                                    class="fe fe-paperclip"></i>Attachments</a>
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div>
                        </div>
                    </div>

                    <div class="mt-3" v-if="detailLoad.under_process">
                        <div class="box border rounded p-4"
                             v-if="detailLoad.under_process.operation === 'cc_inbound_load'">
                            <h4><span class="text-uppercase">{{$t('title.inboundLoad')}}: CC-#{{detailLoad.under_process.cc_inbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.cc_inbound_load.supplier_carrier">
                                            {{detailLoad.under_process.cc_inbound_load.supplier_carrier.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.fromLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.cc_inbound_load.from_location">
                                            <print-ad titled="1"
                                                      :item="detailLoad.under_process.cc_inbound_load.from_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.toLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.cc_inbound_load.to_location">
                                            <print-ad titled="1"
                                                      :item="detailLoad.under_process.cc_inbound_load.to_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.driver')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.under_process.cc_inbound_load.driver">
                                            {{detailLoad.under_process.cc_inbound_load.driver ?
                                            detailLoad.under_process.cc_inbound_load.driver.name : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.transportVehicle')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"
                                             v-if="detailLoad.under_process.cc_inbound_load.transport_vehicle">
                                            {{detailLoad.under_process.cc_inbound_load.transport_vehicle.truck_plate_number}}
                                            <span
                                                v-if="detailLoad.under_process.cc_inbound_load.transport_vehicle.trailer_plate_number">
                                                | {{detailLoad.under_process.cc_inbound_load.transport_vehicle.trailer_plate_number}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row
                                    v-show="detailLoad.under_process.cc_inbound_load.unload_by_carrier_document.length > 0">
                                    <b-col md="6" lg="6" sm="12"><h6>
                                        <span class="text-uppercase">{{$t('title.file')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span>
                                                <a v-if="detailLoad.under_process.cc_inbound_load.unload_by_carrier_document.length"
                                                   v-b-modal.files-attachments
                                                   @click="getAttachmentItem(detailLoad.under_process.cc_inbound_load.unload_by_carrier_document,$t('title.fileAttachment'))"><i
                                                    class="fe fe-paperclip"></i>Attachments</a>
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div>
                        </div>
                    </div>

                    <b-row v-if="detailLoad.order_unload_attachments">
                        <b-col md="6" lg="6" sm="12"><h6>
                            <span class="text-uppercase">{{$t('title.file')}}</span>
                        </h6></b-col><!--/.col-->
                        <b-col md="6" lg="6" sm="12">
                            <div class="text-right">
                                <span>
                                    <a v-if="detailLoad.order_unload_attachments" v-b-modal.files-attachments
                                       @click="getAttachmentItem(detailLoad.order_unload_attachments,$t('title.fileAttachment'))"><i
                                        class="fe fe-paperclip"></i>Attachments</a>
                                </span>
                            </div>
                        </b-col><!--/.col-->
                    </b-row><!--/.row-->

                    <div class="box border rounded p-4">
                        <b-tabs content-class="mt-3" fill>
                            <b-tab :title="$t('title.additionalServices')" active>
                                <div class="mt-3" v-if="detailLoad.additional_services">
                                    <div class="clearfix">
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{{$t('column.additionalService')}}</th>
                                                <th>{{$t('column.description')}}</th>
                                                <th>{{$t('column.compoundNote')}}</th>
                                                <th>ETR</th>
                                                <th>{{$t('column.status')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(service,index) in detailLoad.additional_services">
                                                <td>{{index + 1}}</td>
                                                <td>{{service.additional_service.title}}</td>
                                                <td>{{service.description}}</td>
                                                <td>{{service.compound_notes}}</td>
                                                <td>{{service.estimated_time_repair ?
                                                    $global.dateFormat(service.estimated_time_repair): ''}}
                                                </td>
                                                <td>
                                                    <span v-if="service.is_rejected">
                                                        {{ $t('title.rejected') }}
                                                    </span>
                                                    <span v-else>
                                                        {{(!(!service.marked_done_at)) ? $t('title.done') : $t('title.pending') }}
                                                    </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tfoot
                                                v-if="detailLoad.additional_services && detailLoad.additional_services.length == 0">
                                            <tr>
                                                <td colspan="6">{{$t('title.noDataAvailable')}}</td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div><!--/.box-->
                            </b-tab>
                            <b-tab :title="$t('title.damageHistory')">
                                <div class="mt-3" v-if="detailLoad.damage_histories">
                                    <div class="clearfix">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.creator')}}</th>
                                                    <th>ETR</th>
                                                    <th>{{$t('column.controllerName')}}</th>
                                                    <th>{{$t('column.comments')}}</th>
                                                    <th>{{$t('column.attachment')}}</th>
                                                    <th>{{$t('column.createdAt')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(damage, index) in detailLoad.damage_histories">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{damage.author}}</td>
                                                    <td>{{damage.etr ? $global.utcDateToLocalDate(damage.etr,
                                                        'DD.MM.YYYY',
                                                        'YYYY-MM-DD'): ''}}
                                                    </td>
                                                    <td>{{damage.controller_name}}</td>
                                                    <td>{{damage.comments}}</td>
                                                    <td><a v-if="damage.order_damage_attachments.length"
                                                           v-b-modal.files-attachments
                                                           @click="getAttachmentItem(damage.order_damage_attachments,$t('title.damageHistoriesAttachment'))">
                                                        <i class="fe fe-paperclip"></i>Attachments</a>
                                                        <a v-else href="javascript:;">No Attachment</a>
                                                    </td>
                                                    <td>{{damage.created_at ?
                                                        $global.utcDateToLocalDate(damage.created_at):
                                                        ''}}
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <tfoot
                                                    v-if="detailLoad.damage_histories && detailLoad.damage_histories.length == 0">
                                                <tr>
                                                    <td colspan="7">{{$t('title.noDataAvailable')}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div><!--/.box-->
                            </b-tab>
                        </b-tabs>
                    </div>

                    <div class="mt-3" v-if="detailLoad.outbound_load">
                        <div class="box border rounded p-4">
                            <h4><span class="text-uppercase">{{$t('title.outboundLoad')}}: #{{detailLoad.outbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.supplier_carrier">
                                            {{detailLoad.supplier_carrier.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.supplierCompound')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.supplier_compound">
                                            {{detailLoad.supplier_compound.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.fromLocation')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.route">
                                            <print-ad :item="detailLoad.outbound_load.route.from_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.toLocation')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.route">
                                            <print-ad :item="detailLoad.outbound_load.route.to_location"></print-ad>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-uppercase">{{$t('title.dealer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.dealer">
                                            {{detailLoad.outbound_load.dealer.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.driver')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.drive">
                                            {{detailLoad.outbound_load.drive ? detailLoad.outbound_load.drive.name :
                                            ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-uppercase">{{$t('title.transportVehicle')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.outbound_load.transport_vehicle">
                                            <span v-if="detailLoad.outbound_load.transport_vehicle.truck_plate_number"> {{ detailLoad.outbound_load.transport_vehicle.truck_plate_number }} </span>
                                            <span
                                                v-if="detailLoad.outbound_load.transport_vehicle.trailer_plate_number"> | {{ detailLoad.outbound_load.transport_vehicle.trailer_plate_number }} </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-uppercase">{{$t('title.status')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <ibl-status :status="detailLoad.outbound_load.status"></ibl-status>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                </div><!--/.card-->
            </b-col><!--/.col-->
        </b-row><!--/.row-->
        <!--  attachment modal-->
        <b-modal id="files-attachments" size="xl" ok-only :title="selectedAttachmentTitle" ok-title="Cancel">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{{$t('column.attachment')}}</th>
                        <th>{{$t('column.download')}}</th>
                        <th>{{$t('column.dateAndTime')}}</th>
                        <th>{{$t('column.updatedBy')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(file,index) in selectedAttachment">
                        <td>{{index+1}}</td>
                        <td>{{file.title}}.{{file.document.extension}}</td>
                        <td>
                            <a :title="$t('button.download')"
                               v-b-tooltip.hover variant="outline-primary"
                               class="mb-1"
                               v-if="file.document && file.document.download_url"
                               :disabled="!(file.document && file.document.download_url)"
                               :href="(file.document ? file.document.download_url : '')"
                               target="_blank">
                                <i class="fe fe-download"></i>
                            </a>
                        </td>
                        <td>
                            {{file.document.created_at ? $global.utcDateToLocalDate(file.document.created_at):
                            ''}}
                        </td>
                        <td>
                            {{file.document.author ? file.document.author: ''}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div><!--/div-->
</template>
<script>
    import {serverError} from "../../../../util/Notify";
    import {request} from "../../../../util/Request";

    export default {
        props: ['id', 'handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
                items: [],
                selectedAttachment: [],
                selectedAttachmentTitle: null,
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                serverError(this.$notification)
            }
        },
        methods: {
            getAttachmentItem(item, title) {
                this.selectedAttachment = item
                this.selectedAttachmentTitle = title
            },
            async getDetail(id) {
                await request({
                    url: `archives/supplier/compounds/orders/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        }
    }
</script>
